import { Fonts } from "../constants/text"

export const getFontFamilyClassName = (font?: Fonts): string => {
  if (font === Fonts.medium) {
    return "font-medium"
  }
  if (font === Fonts.medium25) {
    return "font-grotesk-25 font-medium"
  }
  if (font === Fonts.normal) {
    return "font-normal"
  }
  if (font === Fonts.normal25) {
    return "font-grotesk-25"
  }
  if (font === Fonts.semiBold) {
    return "font-semibold"
  }
  if (font === Fonts.semiBold25) {
    return "font-grotesk-25 font-semibold"
  }
  if (font == Fonts.bold10) {
    return "font-grotesk-10 font-bold"
  }

  return "inherit"
}

export const getTextAlignClass = (alignment?: Storyblok.TextAlign): string => {
  if (alignment === "left") {
    return "text-left"
  }
  if (alignment === "center") {
    return "text-center"
  }
  if (alignment === "right") {
    return "text-right"
  }
  if (alignment === "justify") {
    return "text-justify"
  }
  return ""
}
