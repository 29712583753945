import classNames from "classnames"
import React from "react"

import Typography from "./Typography"
import { Color } from "../../../constants/V2/color"

import { getTextColorClass } from "@utils/V2/color"

type CheckIconProps = {
  overrideColor?: Color
}

function CheckIcon({ overrideColor = Color.Charcoal }: CheckIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("self-start", getTextColorClass(overrideColor))}
    >
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.9997C20 16.4182 16.4187 20 12 20C7.582 20 4 16.4182 4 11.9997C4 7.58117 7.582 4 12 4C16.4187 4 20 7.58117 20 11.9997Z"
        stroke="currentColor"
        strokeWidth="1.46934"
      />
      <path
        d="M9.08203 11.9998L11.327 14.2446L15.4087 10.1631"
        stroke="currentColor"
        strokeWidth="1.46934"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

type Props = {
  text: string
  size?: "body-lg" | "body-md"
  answerText?: string
  iconOverrideColor?: Color
  textColor?: Color
}

export default function BulletItem({
  text,
  size = "body-lg",
  answerText,
  iconOverrideColor,
  textColor = Color.Charcoal,
}: Props) {
  return (
    <div
      className={classNames(
        "grid grid-cols-[min-content_auto_auto] items-center gap-x-8-v2",
        getTextColorClass(textColor)
      )}
    >
      <CheckIcon overrideColor={iconOverrideColor} />

      <Typography
        color={textColor}
        text={text}
        font="grotesk"
        size={size}
        weight="book"
        className="mt-px"
      />

      {answerText ? (
        <Typography
          color={textColor}
          text={answerText}
          font="grotesk"
          size={size}
          weight="medium"
          className="justify-self-end mt-px text-right self-start"
        />
      ) : null}
    </div>
  )
}
