import classNames from "classnames"
import React, { useMemo } from "react"

import { TextSize, Fonts } from "../constants/text"
import { getTextColorV1Class } from "../utils/color"
import { getTextSizeClass } from "../utils/sizing"
import { getFontFamilyClassName } from "../utils/text"

/* TODO: Delete this component when Typography is fully implemented */

type Props = {
  text: string
  textSize?: TextSize
  color?: Storyblok.ColorV1
  className?: string
  fontFamily?: Fonts
}

const Text: React.FC<Props> = ({
  text,
  textSize = TextSize.normal,
  color,
  className = "",
  fontFamily,
  ...props
}) => {
  const combinedClassName = useMemo(() => {
    return classNames(
      getTextSizeClass(textSize),
      getTextColorV1Class(color),
      getFontFamilyClassName(fontFamily),
      className
    )
  }, [textSize, color, className, fontFamily])

  return (
    <p className={combinedClassName} {...props}>
      {text}
    </p>
  )
}

export default React.memo(Text)
