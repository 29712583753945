export enum HeadingSize {
  tiny = "tiny",
  extraSmall = "extra-small",
  small = "small",
  smaller = "smaller",
  normal = "normal",
  large = "large",
  extraLarge = "extra-large",
  overSized = "over-sized",
}

export enum Fonts {
  normal = "normal",
  normal25 = "normal25",
  medium = "medium",
  semiBold = "semi-bold",
  medium25 = "medium-25",
  semiBold25 = "semi-bold-25",
  bold10 = "bold-10",
}

export enum TextSize {
  extraSmall = "extra-small",
  small = "small",
  smaller = "smaller",
  normal = "normal",
  larger = "larger",
  large = "large",
  extraLarge = "extra-large",
  inherit = "inherit",
}

// TODO: Rename this when Typography is fully implemented and this enum is only used by Typography (probably)
// MARK: used in v2
export enum HeadingElement {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  p = "p",
}

export enum ListIconType {
  circle = "circle",
  check = "check",
}

export enum TextAlign {
  left = "left",
  center = "center",
  right = "right",
  justify = "justify",
}
