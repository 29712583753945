import { ArrowSize } from "../constants/sizing"
import { ButtonSize } from "../constants/spacing"
import { TextSize } from "../constants/text"

export const getArrowWidthClassName = (size: ArrowSize): string => {
  switch (size) {
    case ArrowSize.small: {
      return "18px"
    }
    case ArrowSize.normal: {
      return "20px"
    }
    case ArrowSize.large: {
      return "30px"
    }
    case ArrowSize.extraLarge: {
      return "39px"
    }
    default:
      return "18px"
  }
}

export const getArrowHeightClassName = (size: ArrowSize): string => {
  switch (size) {
    case ArrowSize.small: {
      return "18px"
    }
    case ArrowSize.normal: {
      return "20px"
    }
    case ArrowSize.large: {
      return "18px"
    }
    case ArrowSize.extraLarge: {
      return "24px"
    }
    default:
      return "18px"
  }
}

// TODO: Remove this function when Typography is fully implemented
export const getTextSizeClass = (size?: TextSize | undefined): string => {
  let classes = ""
  switch (size) {
    case TextSize.extraSmall: {
      classes += "text-fs-10"
      break
    }
    case TextSize.small: {
      classes += "text-fs-11"
      break
    }
    case TextSize.smaller: {
      classes += "text-fs-13"
      break
    }
    case TextSize.normal: {
      classes += "text-fs-13 md:text-fs-14"
      break
    }
    case TextSize.larger: {
      classes += "text-fs-13 md:text-fs-15"
      break
    }
    case TextSize.large: {
      classes += "text-fs-16 md:text-fs-18"
      break
    }
    case TextSize.extraLarge: {
      classes += "text-fs-18 md:text-fs-20"
      break
    }
    case TextSize.inherit: {
      classes += ""
      break
    }
    default:
      classes = ""
  }
  return classes
}

export const getIconButtonDiameter = (size?: ButtonSize): number => {
  let buttonDiameter = 0
  switch (size) {
    case "small":
      buttonDiameter = 40
      break
    case "normal":
      buttonDiameter = 60
      break
    case "large":
      buttonDiameter = 80
      break
    default:
      buttonDiameter = 60
  }

  return buttonDiameter
}
