import { useCallback } from "react"

import { useGetProductBySku } from "@hooks/products"
import { getProductPrices } from "@utils/shop"
import { getUrlFromStoryblokLink } from "@utils/storyblok"
import type { NumberDisplayAdornmentPosition } from "components/elements/V2/NumberDisplay"

export interface Product {
  id: string
  sku: string
}
interface Bundle {
  products: Product[]
  bundleId?: string
  bundleName?: string
}

//Structure a storyblok Bundle or Product in same way to be consumable by the checkout process and addToCartButton component
export const useGetBundle = () => {
  const getBundle = useCallback(
    (object: Storyblok.Product | Storyblok.Bundle): Bundle => {
      if (object.content.component === "product") {
        const product = object as Storyblok.Product
        return {
          products: [
            {
              id: product.uuid,
              sku: product?.content?.sku,
            },
          ],
        }
      } else {
        // Assume this is a bundle object
        const bundle = object as Storyblok.Bundle
        const products = bundle.content.products.map((product) => {
          return {
            id: product.uuid,
            sku: product.content?.sku,
          }
        })

        return {
          products: products,
          bundleId: bundle.uuid,
          bundleName: bundle.content.name,
        }
      }
    },
    []
  )

  return getBundle
}
export interface EcoSystemFields {
  name: string
  price?: string | number
  originalPrice?: number
  priceTerm?: string
  priceType: "price" | "percentage" | "custom"
  priceSideScript?: string
  priceSubScript?: string
  pageLink?: Storyblok.Link
  detailsLink?: Storyblok.Link
  shortDescription: string | undefined
  adornmentTextPosition: NumberDisplayAdornmentPosition
  adornmentText: string
}

export const useGetEcoSystemObjectFields = () => {
  const getSdkProduct = useGetProductBySku()
  const getEcoSystemObjectFields = useCallback(
    /**
     * This function accepts the following objects: Product; Package;
     * and returns a consolidated object of common fields shared between these objects.
     *
     * These should be used for eco-system components that reference these objects
     * interchangeably.
     */
    (
      object:
        | Storyblok.Product
        | Storyblok.Package
        | Storyblok.Fee
        | Storyblok.Bundle
    ): EcoSystemFields => {
      if (object.content.component === "product") {
        const product = object as Storyblok.Product
        const sdkProduct = getSdkProduct(product.content.sku)

        const prices = getProductPrices(
          sdkProduct,
          product.content.price,
          product.content.forceStoryblokPrice,
          product.content.promotionPrice
        )

        //TODO these values should compare to sdk GetProductBySKU stuff
        return {
          name: product.content.name,
          price: prices?.price ? parseFloat(prices?.price) : undefined,
          originalPrice: prices?.originalPrice
            ? parseFloat(prices?.originalPrice)
            : undefined,
          priceTerm: "once-off",
          priceType: "price",
          priceSideScript: product.content.priceSuffixLabel,
          priceSubScript: product.content.priceInfoText,
          pageLink: product.content.buyLink,
          detailsLink: product.content.detailsLink,
          shortDescription: product.content.shortDescription,
          adornmentTextPosition: "left",
          adornmentText: "R",
        }
      } else if (object.content.component === "bundle") {
        const bundle = object as Storyblok.Bundle

        let price = 0
        let originalPrice = 0

        bundle.content?.products.forEach((product) => {
          const sdkProduct = getSdkProduct(product.content.sku)
          const prices = getProductPrices(
            sdkProduct,
            bundle.content.price.toString(),
            false
          )

          price += parseFloat(prices.price)

          if (prices.originalPrice) {
            originalPrice += parseFloat(prices.originalPrice)
          } else {
            originalPrice += parseFloat(prices.price)
          }
        })

        return {
          name: bundle.content.name,
          price: price,
          originalPrice: originalPrice < price ? originalPrice : undefined,
          priceTerm: "once-off",
          priceType: "price",
          priceSideScript: bundle.content.priceSidescript,
          priceSubScript: bundle.content.priceSubscript,
          pageLink: bundle.content.shopPage,
          detailsLink: bundle.content.cataloguePage,
          shortDescription: bundle.content.shortDescription,
          adornmentTextPosition: "left",
          adornmentText: "R",
        }
      } else if (object.content.component === "fee_type") {
        const fee = object as Storyblok.Fee

        const price = fee.content.breakpoints.reduce(
          (prevValue, currentBreakpoint) => {
            return Math.max(
              prevValue,
              parseFloat(currentBreakpoint.chargePercentage || "0")
            )
          },
          0
        )

        return {
          name: fee.content.title,
          price: price,
          priceTerm: "excl. vat",
          priceType: "percentage",
          pageLink: fee.content.pageLink,
          shortDescription: fee.content.shortDescription,
          adornmentTextPosition: "right",
          adornmentText: "%",
        }
      }
      // Assume this is a package object
      else {
        const yocoPackage = object as Storyblok.Package

        return {
          name: object.content.name,
          price:
            yocoPackage.content.priceType === "custom"
              ? yocoPackage.content.price
              : parseFloat(yocoPackage.content.price),
          priceTerm: yocoPackage.content.priceTerm,
          pageLink: yocoPackage.content.pageLink,
          shortDescription: yocoPackage.content.shortDescription,
          priceType: yocoPackage.content.priceType,
          adornmentTextPosition:
            yocoPackage.content.priceType === "price" ? "left" : "right",
          adornmentText: yocoPackage.content.priceType === "price" ? "R" : "%",
        }
      }
    },
    [getSdkProduct]
  )

  return getEcoSystemObjectFields
}

export const useGetConsolidatedObjectFields = () => {
  const getSdkProduct = useGetProductBySku()
  const getConsolidatedObjectFields = useCallback(
    /**
     * This function accepts the following objects: Product; Package;
     * and returns a consolidated object of common fields shared between these objects.
     *
     * These should be used for eco-system components that reference these objects
     * interchangeably.
     */
    (
      object: Storyblok.Product | Storyblok.Bundle,
      quantity: number
    ): YocoCom.ConsolidatedProduct | undefined => {
      if (object.content.component === "product") {
        const product = object as Storyblok.Product
        const sdkProduct = getSdkProduct(product.content.sku)

        if (!sdkProduct) {
          return undefined
        }

        const prices = getProductPrices(
          sdkProduct,
          product.content.price,
          product.content.forceStoryblokPrice,
          product.content.promotionPrice
        )

        return {
          id: product.uuid,
          sku: product.content.sku ?? sdkProduct.sku,
          name: sdkProduct.short_name ?? product.content.name,
          image: product.content.image ?? sdkProduct.image,
          quantity: quantity,
          maxQuantity: sdkProduct?.max_quantity_per_cart,
          price: prices.price,
          originalPrice: prices.originalPrice,
          detailLink: getUrlFromStoryblokLink(product.content.detailsLink),
          description: product.content.shortDescription,
          galleryItems: product.content.galleryItems,
          priceSideScript: product.content.priceSuffixLabel,
          priceSubScript: product.content.priceInfoText,
          thirdPartyReviews: sdkProduct.third_party_reviews,
          recommendedProducts: product.content.recommendedProducts,
          showRecommendedProductsInCartPreview:
            product.content.showRecommendedProductsInCartPreview,
          promotionStartDate: product.content.promotionStartDate,
          promotionEndDate: product.content.promotionEndDate,
          reasonNotAvailable: sdkProduct.reason_not_available,
        }
      } else {
        const bundle = object as Storyblok.Bundle
        const parentProduct = bundle.content?.products[0]
        const sdkProduct = getSdkProduct(parentProduct.content.sku)

        if (!sdkProduct) {
          return undefined
        }
        let price = 0
        let originalPrice = 0

        bundle.content?.products.forEach((product) => {
          const sdkProduct = getSdkProduct(product.content.sku)
          const prices = getProductPrices(
            sdkProduct,
            bundle.content.price.toString(),
            false
          )

          price += parseFloat(prices.price)

          if (prices.originalPrice) {
            originalPrice += parseFloat(prices.originalPrice)
          } else {
            originalPrice += parseFloat(prices.price)
          }
        })

        return {
          id: bundle.uuid,
          sku: parentProduct.content.sku,
          name: bundle.content.name,
          image: bundle.content.image,
          quantity: quantity,
          maxQuantity: sdkProduct?.max_quantity_per_cart,
          price: price.toString(),
          originalPrice:
            originalPrice < price ? originalPrice.toString() : undefined,
          detailLink: getUrlFromStoryblokLink(
            parentProduct.content.detailsLink
          ),
          description: bundle.content.shortDescription,
          priceSideScript: bundle.content.priceSidescript,
          priceSubScript: bundle.content.priceSubscript,
          galleryItems: bundle.content.galleryItems,
          promotionStartDate: bundle.content.promotionStartDate,
          promotionEndDate: bundle.content.promotionEndDate,
          reasonNotAvailable: sdkProduct.reason_not_available,
          bundleId: bundle._uid,
          bundleName: bundle.content.name,
          isBundleParent: true,
        }
      }
    },
    [getSdkProduct]
  )

  return getConsolidatedObjectFields
}
