import classNames from "classnames"
import React from "react"

import { getTextColorClass } from "@utils/V2/color"
import { Color } from "constants/V2/color"

type Props = {
  className?: string
  width?: number
  color?: Color
}

const StarIcon: React.FC<Props> = ({ color, width = 16 }: Props) => {
  return (
    <svg
      width={width}
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("fill-current", getTextColorClass(color))}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 1.93066L9.95602 5.926L14.3327 6.57066L11.166 9.67866L11.9134 14.0693L7.99935 11.9953L4.08535 14.0693L4.83268 9.67866L1.66602 6.57066L6.04202 5.926L7.99935 1.93066Z"
        strokeWidth="1.89715"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StarIcon
