import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import styled from "styled-components"

import { getGatsbyImage } from "../../../plugins/storyblok-image-plugin"
import { getImageDimensionsFromUrl } from "../../utils/image"

const StyledImage = styled.img<{
  width?: number
  height?: number
}>`
  width: ${({ width }) => (width ? `${width}px` : "unset")};
  max-height: ${({ height }) => (height ? `${height}px` : "unset")};
`

type Props = React.HTMLAttributes<HTMLDivElement> & {
  image: string
  width?: number
  height?: number
  className?: string
  roundedLeftSize?: number
  alt?: string | null
  loadType?: "eager" | "lazy"
  quality?: number
  layout: "fixed" | "fullWidth" | "constrained"
  // TODO: This should probably be handled elsewhere
  rounded?: boolean
  aspectRatio?: number
  fitHeight?: boolean
}

const GatsbyStoryBlokImage: React.FC<Props> = ({
  image,
  width,
  height,
  className,
  alt,
  layout,
  loadType = "lazy",
  quality = undefined,
  rounded = false,
  roundedLeftSize = undefined,
  aspectRatio,
  fitHeight = false,
}) => {
  const imageDimensions = useMemo(() => {
    return getImageDimensionsFromUrl(image)
  }, [image])

  const imageProps = getGatsbyImage(image, {
    width,
    height,
    layout,
    quality,
    aspectRatio,
  })

  const isSvg = image.includes(".svg")

  if (isSvg) {
    return (
      <StyledImage
        loading={"lazy"}
        className={classNames(
          { "rounded overflow-hidden translate-x-0": rounded },
          "max-w-full",
          className
        )}
        src={image}
        alt={alt || ""}
        width={width || parseInt(imageDimensions.width ?? "0")}
        height={height || parseInt(imageDimensions.height ?? "0")}
      />
    )
  } else {
    return (
      <GatsbyImage
        imgClassName={
          layout === "fixed" ? "!object-contain !relative" : undefined
        }
        image={imageProps}
        alt={alt || ""}
        loading={loadType}
        style={
          roundedLeftSize
            ? {
                borderTopLeftRadius: `${roundedLeftSize}px`,
                borderBottomLeftRadius: `${roundedLeftSize}px`,
              }
            : undefined
        }
        className={classNames(
          { "rounded overflow-hidden translate-x-0": rounded },
          "max-w-full",
          className,
          { "!h-auto": layout === "fixed" && !fitHeight },
          { "!h-full !w-auto": fitHeight }
        )}
      />
    )
  }
}

export default GatsbyStoryBlokImage
