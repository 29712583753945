import React from "react"

type Props = {
  className?: string
}

const PlusIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 632037">
        <rect
          id="Rectangle 1697"
          x="10"
          width="18"
          height="2"
          transform="rotate(90 10 0)"
        />
        <rect id="Rectangle 1698" y="8" width="18" height="2" />
      </g>
    </svg>
  )
}

export default PlusIcon
